import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dialog, DialogClose, DialogContent } from "./ui/Dialog";
import { IconButton } from "./ui/IconButton";
import { PublicConfig } from "../PublicConfig";
import { UIUtils } from "../utils/UIUtils";
import Strings from "../utils/Strings.constants";
import { DialogProps } from "@/shared/types/Modals";

const MemberCenterModal: React.FC<DialogProps> = props => {
    const { open, onClose } = props;

    function handleOverlayClick() {
        if (onClose) {
            onClose(false);
        }
    }

    function handleContentClick(event: React.MouseEvent) {
        event.stopPropagation();
    }

    return (
        <div onClick={handleOverlayClick}>
            <Dialog open={open}>
                <DialogContent onEscapeKeyDown={handleOverlayClick} onClick={handleContentClick}>
                    <DialogClose asChild>
                        <IconButton onClick={() => onClose && onClose(false)}>
                            <FontAwesomeIcon icon={faXmark} className="size-5 text-content-primary" />
                        </IconButton>
                    </DialogClose>
                    <div className="text-left leading-7 text-content-primary">
                        <div className="mb-3 text-lg font-bold">
                            Looks like you already have <br className="block md:hidden" />
                            an account
                        </div>
                        <p className="mb-3 text-base">
                            Visit My Pets section of the <br className="block md:hidden" />
                            <a href={PublicConfig.MEMBER_LOGIN_URL} target="_blank" rel="noopener noreferrer">
                                <span className="text-content-brand underline">Member Center website</span>
                            </a>{" "}
                            or mobile app to
                            <br className="hidden md:block" /> get a quote and add another pet.
                        </p>
                        <p>
                            You can also call{" "}
                            <a href={`tel:${UIUtils.formatPhone(Strings.PTZ_US.PHONE_NUMBER)}`}>
                                <span className="text-content-brand underline">{UIUtils.formatPhone(Strings.PTZ_US.PHONE_NUMBER)}</span>
                            </a>{" "}
                            to speak with a Help Center representative.
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default MemberCenterModal;
