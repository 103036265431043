import React from "react";
import { useDeepCompareMemo } from "@react-hookz/web";
import { DefaultValues, FieldValues, UseFormReturn } from "react-hook-form";
import { FormStep, OtherProps } from "../types/Form";
import { Divider, Heading } from "../components/ui";
import { ConditionalRender } from "../components/ConditionalRender";
import { FormStepWrapper } from "../components/FormStepWrapper";

interface StepRendererProps<T extends FieldValues, StepIds extends string, K extends keyof T, O = OtherProps | undefined> {
    step: FormStep<T, StepIds, K, O>;
    index: number;
    value?: T;
    lastStepIndex: number;
    otherValuesMap?: Record<string, OtherProps>;
    register: (stepId: string, methods: UseFormReturn<any>) => void;
    unregister: (stepId: string) => void;
    lastStepRef: React.RefObject<HTMLDivElement>;
}

export const StepRenderer = <T extends FieldValues, StepId extends string, K extends keyof T, O = OtherProps | undefined>({
    step,
    index,
    value,
    lastStepIndex,
    otherValuesMap,
    register,
    unregister,
    lastStepRef
}: StepRendererProps<T, StepId, K, O>) => {
    const otherStepValues = otherValuesMap?.[step.id] as O;
    // Memoize or stabilize any variables or functions
    const initialValues = useDeepCompareMemo(() => {
        if (step.getInitialValues) {
            const otherStepValues = otherValuesMap?.[step.id] as O;
            return step.getInitialValues(value, otherStepValues);
        }
        return undefined;
    }, [step, value, otherValuesMap]);

    const stepControl = step.render({
        value: value,
        otherValues: otherStepValues,
        isLastVisible: index === lastStepIndex
    });

    return (
        <div className="flex flex-col gap-4" ref={lastStepRef}>
            {index > 0 && <Divider />}
            {step.title && (
                <div className="flex flex-row items-center">
                    <Heading level="h1" className="text-lg font-bold">
                        {step.title}
                    </Heading>
                    {step.titleWidget && (
                        <div className="ml-auto">
                            {React.createElement(step.titleWidget, {
                                value: value,
                                isLastVisible: index === lastStepIndex
                            })}
                        </div>
                    )}
                </div>
            )}
            <ConditionalRender monitor={value} shouldRender={step.shouldRerender ?? (() => true)}>
                {step.stepSchema && !!initialValues ? (
                    <FormStepWrapper
                        schema={step.stepSchema}
                        options={{ defaultValues: initialValues as DefaultValues<Pick<T, K> & O> | undefined }}
                        register={register}
                        unregister={unregister}
                        stepId={step.id}
                    >
                        {stepControl}
                    </FormStepWrapper>
                ) : (
                    stepControl
                )}
            </ConditionalRender>
        </div>
    );
};
