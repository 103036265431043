import { useQuery } from "@tanstack/react-query";
import { QuoteAPI } from "../utils/QuoteAPI";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";

type useMemberCenterStatusProps = {
    email?: string;
    underwriter: PetUnderwriterType;
};

export const useMemberCenterStatus = (props: useMemberCenterStatusProps) => {
    const { email, underwriter } = props;
    const quoteApi = new QuoteAPI(underwriter as PetUnderwriterType);

    // Use useQuery to verify member center registration
    const {
        data: isVerified,
        isFetching: isVerifying,
        error: verifyError
    } = useQuery<Promise<boolean>>({
        queryKey: [`verifyMemberCenterRegistration`, email],
        queryFn: async () => {
            if (!email) return false;
            try {
                const response = await quoteApi.verifyEmailIsRegistered(email);
                return response;
            } catch (error) {
                return false;
            }
        },
        // Add options as needed, for example, to enable or disable this query based on accountId's presence
        enabled: !!email
    });

    return {
        isVerified, // Data from the verification process
        isVerifying, // Whether the verification query is in progress
        verifyError // Any error that occurred during verification
    };
};
