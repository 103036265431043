import React, { useEffect, useRef } from "react";
import { useForm, FormProvider, FieldValues, UseFormReturn, UseFormProps, DefaultValues } from "react-hook-form";
import { ZodSchema, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface FormStepWrapperProps<T extends FieldValues> {
    schema: ZodSchema<T>;
    children: React.ReactNode;
    stepId: string;
    options?: UseFormProps<T>;
    register: (stepId: string, methods: UseFormReturn<T>) => void;
    unregister: (stepId: string) => void;
}

export function FormStepWrapper<T extends FieldValues>({ schema, children, options, register, unregister, stepId }: FormStepWrapperProps<T>) {
    const methods = useForm<z.infer<typeof schema>>({ resolver: zodResolver(schema), ...options });
    const {
        formState: { dirtyFields, isDirty }
    } = methods;
    const defaultValues = options?.defaultValues;
    const initialValuesRef = useRef(defaultValues);

    useEffect(() => {
        register(stepId, methods);

        return () => {
            unregister(stepId);
        };
    }, [register, unregister, methods, stepId]);

    useEffect(() => {
        if (!!defaultValues && !!initialValuesRef.current && JSON.stringify(defaultValues) !== JSON.stringify(initialValuesRef.current)) {
            initialValuesRef.current = defaultValues;
            methods.reset(defaultValues as DefaultValues<z.infer<typeof schema>>, {
                keepValues: true,
                keepTouched: true,
                keepDirtyValues: true,
                keepDirty: true,
                keepIsSubmitted: true,
                keepSubmitCount: true,
                keepErrors: true
            });
        }
    }, [defaultValues, methods]);

    return <FormProvider {...methods}>{children}</FormProvider>;
}
