import { useQuery } from "@tanstack/react-query";
import { PtzUsDataUtils } from "@/quote-ptz-us/src/utils/PtzUsDataUtils";
import { z } from "zod";

const ZipcodeSchema = z.string().length(5).regex(/^\d+$/, "Zipcode must consist of digits only");

interface StateFromZip {
    abbreviation: string;
    name: string;
}

export const useStateFromZipcode = (ratingZipcode?: string) => {
    const validatedZipcode = ZipcodeSchema.safeParse(ratingZipcode);

    const fetchStateFromZip = async (): Promise<StateFromZip | null> => {
        if (!validatedZipcode.success) {
            throw new Error("Invalid or missing zipcode");
        }
        const stateObject = await PtzUsDataUtils.getStateFromZip(validatedZipcode.data);
        return stateObject;
    };

    const stateFromZipQuery = useQuery<StateFromZip | null, Error>({
        queryKey: ["stateFromZip", ratingZipcode],
        queryFn: fetchStateFromZip,
        enabled: !!ratingZipcode && validatedZipcode.success,
        retry: false
    });

    const { data: stateFromZip, isFetching, error } = stateFromZipQuery;

    return { stateFromZip, isFetching, error };
};
